/**
 * @description 接口请求处理
 * @param resouceName 请求的资源名对应<Resource/>组件
 * @param type React-admin规定的请求类型
 */
import { prop, map, compose, length } from "ramda";
import buildApolloClient, {
  buildQuery as buildQueryFactory
} from "ra-data-graphql-simple";
import { HttpLink, InMemoryCache } from "apollo-client-preset";

// utils的文件函数都是用R函数进行的数据处理
import {
  parseNode,
  parseEdgesForData,
  parseEdgesForTotal,
  parseUpdateResult,
  parseCreateResult,
  transformParamsForListing,
  transformParamsForGet,
  // 此函数把Resource组件的name转换成query的接口的名字
  transformResourceForListing,
  transformParamsForUpdate,
  transformParamsForGetMany,
  convertNodeIdValue,
  transformParamsForUpdatePassword
} from "./utils";
import config from "./config";

import * as gql from "./gqlQueries";

import header from "./accounts/header";

const customBuildQuery = introspectionResults => {
  const buildQuery = buildQueryFactory(introspectionResults);

  // 接口请求只需关注以下代码
  return (type, resouceName, params) => {
    // type 代表增删改查
    // resourceName 代表Admin组件里的Resource组件对应的name
    // 以下是接口请求类型映射文档
    // https://react-admin.com/docs/zh-CN/data-providers.html
    if (
      type === "GET_LIST" &&
      (resouceName === "Banner" || resouceName === "SignCard")
    ) {
      if (resouceName === "SignCard") {
        const packageId = window.sessionStorage.getItem("packageId");
        const transedName = transformResourceForListing(resouceName);
        const variables = {
          packageId,
          ...transformParamsForListing(params)
        };
        return {
          query: prop(transedName)(gql),
          variables,
          parseResponse: response => {
            return {
              data: parseEdgesForData(transedName)(response),
              total: parseEdgesForTotal(transedName)(response)
            };
          }
        };
      }
      if (resouceName === "Banner") {
        const transedName = transformResourceForListing(resouceName);
        return {
          query: prop(transedName)(gql),
          variables: transformParamsForListing(params),
          parseResponse: response => {
            const data = map(
              convertNodeIdValue,
              prop(transedName)(response.data)
            ).map(item => {
              // 替换linkUrl为空字符串，因为Resource列表无法渲染空对象
              if (Object.keys(item.linkUrl).length === 0) {
                item.linkUrl = "";
              }
              return item;
            });

            return {
              data,
              total: compose(length, prop(transedName))(response.data)
            };
          }
        };
      }
    } else if (type === "GET_LIST") {
      if (resouceName === "Password") {
        resouceName = "User";
      }

      const transedName = transformResourceForListing(resouceName);

      return {
        query: prop(transedName)(gql),
        variables: transformParamsForListing(params),
        parseResponse: response => {
          return {
            data: parseEdgesForData(transedName)(response),
            total: parseEdgesForTotal(transedName)(response)
          };
        }
      };
    } else if (type === "GET_MANY") {
      if (resouceName === "SignCard") {
        resouceName = "Card";
        const coursePackageId = window.sessionStorage.getItem(
          "coursagePackageId"
        );

        return {
          query: prop(`get${resouceName}`)(gql),
          variables: transformParamsForGetMany(params, resouceName),
          parseResponse: response => {
            const signCard = [parseNode(response)].pop();
            window.sessionStorage.setItem("signCard", JSON.stringify(signCard));
            return { data: [parseNode(response)] };
          }
        };
      } else {
        return {
          query: prop(`get${resouceName}`)(gql),
          variables: transformParamsForGetMany(params, resouceName),
          parseResponse: response => {
            return { data: [parseNode(response)] };
          }
        };
      }
    } else if (type === "UPDATE") {
      if (resouceName === "Banner") {
        return {
          query: prop(`update${resouceName}`)(gql),
          variables: transformParamsForUpdate(params),
          parseResponse: response => {
            let data = parseUpdateResult(resouceName)(response);

            // 替换linkUrl为空字符串，因为Resource列表无法渲染空对象
            if (
              data.linkUrl === null ||
              Object.keys(data.linkUrl).length === 0
            ) {
              data.linkUrl = "";
            }

            return { data };
          }
        };
      }
      if (resouceName === "Purchase") {
        return {
          query: prop(`update${resouceName}`)(gql),
          variables: transformParamsForUpdate(params),
          parseResponse: response => {
            return { data: parseUpdateResult(resouceName)(response) };
          }
        };
      }

      if (resouceName === "User") {
        // 密码修改
        if (transformParamsForUpdate(params).input.password) {
          resouceName = "Password";
          const input = transformParamsForUpdatePassword(["phone", "password"])(
            params.data
          ).input;
          const variables = {
            input: { phone: input.phone, newPassword: input.password }
          };
          return {
            query: prop(`update${resouceName}`)(gql),
            variables,
            parseResponse: response => {
              return { data: response.data.resetUserPassword.User };
            }
          };
        } else {
          // 角色更改
          return {
            query: prop(`update${resouceName}`)(gql),
            variables: transformParamsForUpdate(params),
            parseResponse: response => {
              return { data: parseUpdateResult(resouceName)(response) };
            }
          };
        }
      }
      // 讨论主题列表数据
      if (resouceName === "Discussion") {
        return {
          query: prop(`update${resouceName}`)(gql),
          variables: transformParamsForUpdate(params),
          parseResponse: response => {
            return { data: parseUpdateResult(resouceName)(response) };
          }
        };
      }
      if (resouceName === "CoursePackage") {
        //请求参数，参数没有使用R提供函数工具
        const signCardId = JSON.parse(window.sessionStorage.getItem("signCard"))
          .id;
        const variables = { id: params.id, signCardId };
        return {
          query: prop(`update${resouceName}`)(gql),
          variables: { input: variables },
          parseResponse: response => {
            return { data: response.data.updateCoursePackage.CoursePackage };
          }
        };
      }
    } else if (type === "GET_ONE") {
      if (resouceName === "Password") {
        resouceName = "User";
      }
      return {
        query: prop(`get${resouceName}`)(gql),
        variables: transformParamsForGet(params, resouceName),
        parseResponse: response => {
          if (resouceName === "Banner") {
            let data = parseNode(response);
            // 替换linkUrl为空字符串，因为Resource列表无法渲染空对象
            if (Object.keys(data.linkUrl).length === 0) {
              data.linkUrl = "";
            }
            return { data };
          } else {
            return { data: parseNode(response) };
          }
        }
      };
    } else if (type === "DELETE") {
      if (resouceName === "CoursePackage") {
        resouceName = "SignCard";
        const variables = { id: params.id, signCardId: null, status: "" };
        return {
          query: prop(`delete${resouceName}`)(gql),
          variables: { input: variables },
          parseResponse: response => {
            return { data: response.data.updateCoursePackage.CoursePackage };
          }
        };
      }
      if (resouceName === "Banner") {
        return {
          query: prop(`delete${resouceName}`)(gql),
          variables: { input: { id: params.id } }
        };
      } else {
        return {
          query: prop(`delete${resouceName}`)(gql),
          variables: { input: params }
        };
      }
    } else if (type === "CREATE") {
      return {
        query: prop(`create${resouceName}`)(gql),
        variables: { input: params.data },
        parseResponse: response => {
          if (resouceName === "Banner") {
            if (!response.data.createBanner.errors) {
              return { data: parseCreateResult(resouceName)(response) };
            } else {
              if (
                response.data.createBanner.errors[0].message ===
                "has already been taken"
              ) {
                alert("输入的链接值已在Banners列表里,请重新输入");
                return {
                  data: parseCreateResult(resouceName)(response)
                };
              }
            }
          }

          return { data: parseCreateResult(resouceName)(response) };
        }
      };
    }
    return buildQuery(type, resouceName, params);
  };
};

export default () => {
  return buildApolloClient({
    clientOptions: {
      cache: new InMemoryCache().restore({}),
      link: new HttpLink({
        uri: config.uri + "api",
        headers: header
      })
    },
    introspection: {},
    buildQuery: customBuildQuery
  });
};
