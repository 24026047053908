/**
 * @description 多行文本组件 react-admin支持的material-ui版本1.5.1没有该组件
 */

import React from "react";

const TextArea = props => (
  <>
    <label>{props.label}</label>
    <textarea
      rows={props.rows ? props.rows : "20"}
      cols={props.cols ? props.cols : "100"}
      onChange={props.onChange}
      style={{ display: "block" }}
    />
  </>
);

export default TextArea;
