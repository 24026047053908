/**
 * @description 课程列表 目前还不需要单个课程编辑
 */
import React from "react";
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  NumberField,
  EditButton
} from "react-admin";

export const CourseList = props => (
  <List {...props}>
    <Datagrid>
      <NumberField source="id" sortable={false} />
      <TextField source="title" label="标题" sortable={false} />
      <TextField source="owner.nickname" label="创建人" sortable={false} />
      <BooleanField source="isOnline" label="是否在线" sortable={false} />
      <BooleanField source="isSelling" label="是否在售卖" sortable={false} />
      <TextField source="registrationBegin" label="报名开始时间" />
      <EditButton />
    </Datagrid>
  </List>
);
