/**
 * @description 购买课程列表
 */
import React from "react";
import {
  Filter,
  List,
  Edit,
  Create,
  Datagrid,
  BooleanField,
  TextField,
  NumberField,
  EditButton,
  NumberInput,
  TextInput,
  BooleanInput,
  SimpleForm,
  DisabledInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";

const PurchaseFilter = props => (
  <Filter {...props}>
    <TextInput label="课程名搜索" source="courseTitle" alwaysOn />
    <TextInput label="手机号搜索" source="phone" />
  </Filter>
);

export const PurchaseList = props => (
  <List filters={<PurchaseFilter />} {...props}>
    <Datagrid>
      <NumberField source="id" sortable={false} />
      <TextField source="course.title" label="课程标题" sortable={false} />
      <TextField source="user.nickname" label="用户昵称" sortable={false} />
      <TextField source="user.phone" label="用户手机号" sortable={false} />
      <TextField source="price" label="购买价格" sortable={false} />
      {/* <SelectField source='status' choices={statusChoice} label='状态' sortable={false} /> */}
      <BooleanField source="status" label="状态" sortable={false} />
      <TextField source="insertedAt" label="购买时间" sortable />
      <TextField source="from" label="购买来源" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);
const PurchaseTitle = ({ record }) => {
  return (
    <span>
      {" "}
      {record ? `${record.course.title}: ${record.user.nickname}` : ""}{" "}
    </span>
  );
};

export const PurchaseEdit = props => (
  <Edit title={<PurchaseTitle />} {...props} undoable={false}>
    <SimpleForm>
      <DisabledInput source="id" />
      <DisabledInput source="course.title" />
      <DisabledInput source="user.phone" />
      <BooleanInput source="status" />
    </SimpleForm>
  </Edit>
);
const number = value =>
  value && isNaN(Number(value)) ? "Must be a float number" : undefined;
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

const validatePrice = [number, minValue(0)];

export const PurchaseCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="用户"
          source="userId"
          reference="User"
          filterToQuery={searchText => ({ phone: searchText })}
        >
          <AutocompleteInput optionText="phone" />
        </ReferenceInput>
        <ReferenceInput
          label="课程"
          source="courseId"
          reference="Course"
          filterToQuery={searchText => ({ title: searchText })}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <NumberInput
          source="price"
          label="购买价格"
          validateprice={validatePrice}
        />
        <DisabledInput source="from" label="来源" defaultValue="ADMIN" />
      </SimpleForm>
    </Create>
  );
};
