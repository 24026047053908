import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Select from "@material-ui/core/Select";
import Autosuggest from "react-autosuggest";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Input from "@material-ui/core/Input";

import axios from "axios";

import config from "../config";
import { searchCourse, searchHomework } from "./../query";

const notifyItem = [
  {
    title: "课程上新",
    template_id: "SZeDlB90Cw1AuejL9yevCYUD2vJN9d4aBvlWtmL9QxI",
    infoItem: [
      { title: "课程名称", key: "thing1" },
      { title: "课程内容", key: "thing2" },
      // { title: "时间", key: "date3" },
      { title: "备注", key: "thing6" }
    ]
  },
  {
    title: "作业提交提醒",
    template_id: "nAjW97xCCZDVltka3cUtk3FVytGUgjxjdudk_f3ofPo",
    infoItem: [
      { title: "课程", key: "thing2" },
      { title: "作业标题", key: "thing9" },
      { title: "备注", key: "thing5" }
    ]
  }
];

// 组件样式
const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.title, query);
  const parts = parse(suggestion.title, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

const WeChatNotifyPage = props => {
  const { classes } = props;
  const [suggestions, setSuggestions] = React.useState([]);
  const [homeworkSuggestions, setHomeworkSuggestions] = React.useState([]);
  const [notifyTypeIndex, setNotifyTypeIndex] = React.useState(0);
  const [courseNameInput, setCourseNameInput] = React.useState("");
  const [courseContentInput, setCourseContentInput] = React.useState("");
  const [courseDateInput, setCourseDateInput] = React.useState("2019-01-01");
  const [courseRemarkInput, setCourseRemarkInput] = React.useState("");
  const [courseName, setCourseName] = React.useState("");
  const [courseList, setCourseList] = React.useState([]);
  const [homeworkList, setHomeworkList] = React.useState([]);
  const [homeworkIndex, setHomeworkIndex] = React.useState(0);
  const [isSending, setIsSending] = React.useState(false);

  const [tipInfo, setTipInfo] = React.useState({ isOpen: false, message: "" });

  const handleClose = () => {
    setTipInfo({ isOpen: false, message: "" });
  };
  const getSuggestions = value => {
    return suggestions ? suggestions : [];
  };

  const getSuggestionValue = suggestion => {
    return suggestion;
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = () => (event, { newValue }) => {
    let searchName = typeof newValue === "object" ? newValue.title : newValue;
    setCourseNameInput(searchName);
    let input = {
      first: 20,
      filter: {
        isOnline: true,
        title: searchName
      }
    };
    axios
      .post(
        config.uri + "api",
        {
          query: searchCourse,
          variables: input
        },
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("token")}`
          }
        }
      )
      .then(res => {
        try {
          if (
            res.data.errors &&
            res.data.errors[0].message === "unauthorized"
          ) {
            window.location.href = window.location.origin + "/#/login";
          }
          let list = res.data.data.courses.edges.filter(item => {
            let coursesPackages = item.node.coursesPackages;
            let isHaveAssignment = false;
            for (let i in coursesPackages) {
              if (coursesPackages[i].packageTag === "ASSIGNMENT") {
                isHaveAssignment = true;
              }
            }
            if (
              item.node.title.indexOf(searchName) !== -1 &&
              !item.node.isEnd
            ) {
              if((notifyTypeIndex === 1 || notifyTypeIndex === "1") && !isHaveAssignment) {
                return false;
              }
              return true;
            }
          });
          list = list.map(item => item.node);
          setSuggestions(list);
          setCourseList(list);
          if (
            list.length > 0 &&
            (notifyTypeIndex === 1 || notifyTypeIndex === "1")
          ) {
            let course = list[0];
            let coursePackage = course.coursesPackages.filter(item => {
              if (item.packageTag === "ASSIGNMENT") {
                return true;
              }
            })[0];

            axios
              .post(
                config.uri + "api",
                {
                  query: searchHomework,
                  variables: { id: coursePackage.id }
                },
                {
                  headers: {
                    authorization: `Bearer ${window.localStorage.getItem(
                      "token"
                    )}`
                  }
                }
              )
              .then(res => {
                try {
                  if (
                    res.data.errors &&
                    res.data.errors[0].message === "unauthorized"
                  ) {
                    window.location.href = window.location.origin + "/#/login";
                  }
                  let list = res.data.data.node.assignments;
                  setCourseName(course.title);
                  setHomeworkList(list);
                } catch (err) {
                  console.log(err);
                }
              });
          } else {
            setCourseName("");
            setHomeworkList([]);
            setHomeworkIndex(0);
          }
        } catch (err) {
          console.log(err);
        }
      });
  };

  const sendData = notifyData => {
    setIsSending(true);
    axios
      .post(config.uri + "t/api/mini/send_message", notifyData, {
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`
        }
      })
      .then(res => {
        setIsSending(false);
        setTipInfo({ isOpen: true, message: "发送成功" });
      })
      .catch(res => {
        setIsSending(false);
        setTipInfo({ isOpen: true, message: "发送失败" });
      });
  };

  const sendNewCourseNodify = () => {
    if (courseList.length === 0) {
      setTipInfo({ isOpen: true, message: "请选择正确的卡包" });
      return;
    }
    let index = 0;
    if(courseList.length > 1) {
      let isNotHave = true;
      for(let i in courseList) {
        let course = courseList[i];
        if(course.title === courseNameInput) {
          isNotHave = false;
          index = i;
          break;
        }
      }
      if(isNotHave) {
        setTipInfo({ isOpen: true, message: "请选择正确的卡包" });
        return;
      }
    }
    if (courseContentInput.trim() === "") {
      setTipInfo({ isOpen: true, message: "请填写课程内容" });
      return;
    }
    if (courseRemarkInput.trim() === "") {
      setTipInfo({ isOpen: true, message: "请填写备注" });
      return;
    }
    let dataItem = notifyItem[0];
    let data = {};

    for (let i in dataItem.infoItem) {
      let item = dataItem.infoItem[i];
      if (item.title === "课程名称") {
        data[item.key] = { value: courseList[index].title };
      }
      if (item.title === "课程内容") {
        data[item.key] = { value: courseContentInput };
      }
      if (item.title === "时间") {
        data[item.key] = { value: courseDateInput };
      }
      if (item.title === "备注") {
        data[item.key] = { value: courseRemarkInput };
      }
    }

    let courseId = window.atob(courseList[0].id).split("Course:")[1];
    let notifyData = {
      template_type: "course",
      data: data,
      page: "pages/course/coursemain/CourseMain?isNotify=true&courseId=" + courseId,
      course_id: courseId
    };
    sendData(notifyData);
  };

  const sendHomeworNodify = () => {
    if (courseList.length === 0) {
      setTipInfo({ isOpen: true, message: "请选择正确的卡包" });
      return;
    }
    let index = 0;
    if(courseList.length > 1) {
      let isNotHave = true;
      for(let i in courseList) {
        let course = courseList[i];
        if(course.title === courseNameInput) {
          isNotHave = false;
          index = i;
          break;
        }
      }
      if(isNotHave) {
        setTipInfo({ isOpen: true, message: "请选择正确的卡包" });
        return;
      }
    }
    
    if (homeworkList.length === 0) {
      setTipInfo({ isOpen: true, message: "该课程没有作业" });
      return;
    }
    if (courseRemarkInput.trim() === "") {
      setTipInfo({ isOpen: true, message: "请填写备注" });
      return;
    }
    let homework = homeworkList[homeworkIndex];
    let dataItem = notifyItem[1];
    let data = {};

    for (let i in dataItem.infoItem) {
      let item = dataItem.infoItem[i];
      if (item.title === "课程") {
        data[item.key] = { value: courseList[index].title };
      }
      if (item.title === "作业标题") {
        data[item.key] = { value: homework.name };
      }
      if (item.title === "备注") {
        data[item.key] = { value: courseRemarkInput };
      }
    }

    let courseId = courseList[0].id;
    let notifyData = {
      pid: window.atob(homework.id).split("Assignment:")[1],
      template_type: "assignment",
      data: data,
      course_id: window.atob(courseId).split("Course:")[1],
      page:
        "pages/course/homework/assignment/assignment?isNotify=true&" +
        "&assignmentId=" +
        window.atob(homework.id).split("Assignment:")[1] +
        "&courseId=" +
        window.atob(courseId).split("Course:")[1]
    };
    sendData(notifyData);
  };

  const sendNodify = () => {
    switch (notifyTypeIndex) {
      case 0:
      case "0":
        sendNewCourseNodify();
        break;
      case 1:
      case "1":
        sendHomeworNodify();
        break;
    }
  };

  const autoSuggestProps = {
    renderInputComponent,
    suggestions: suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue: getSuggestionValue,
    renderSuggestion
  };

  const getCourseContentInput = event => {
    setCourseContentInput(event.target.value);
  };

  const getCourseDateInput = event => {
    let date = event.target.value;
    setCourseDateInput(date);
  };

  const getCourseRemarkInput = event => {
    setCourseRemarkInput(event.target.value);
  };

  const renderNewCourseTitleInput = (info, index) => {
    return (
      <Autosuggest
        {...autoSuggestProps}
        inputProps={{
          classes,
          placeholder: "请输入课程名称",
          value: courseNameInput,
          onChange: handleChange()
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper
            {...options.containerProps}
            square
            style={{ overflow: "auto" }}
          >
            {options.children}
          </Paper>
        )}
      />
    );
  };

  const renderNewCourseContentInput = (info, index) => {
    return (
      <TextField
        label="请输入内容"
        inputProps={{ maxLength: 20 }}
        defaultValue={courseContentInput}
        onChange={getCourseContentInput}
        style={{ width: "690px" }}
      />
    );
  };

  const renderNewCourseDateInput = (info, index) => {
    return (
      <TextField
        label="请选择日期"
        InputLabelProps={{ shrink: true }}
        type="date"
        inputProps={{ maxLength: 20 }}
        defaultValue={courseDateInput}
        onChange={getCourseDateInput}
        style={{ width: "200px" }}
      />
    );
  };

  const renderNewCourseRemarkInput = (info, index) => {
    return (
      <TextField
        label="请输入内容"
        inputProps={{ maxLength: 20 }}
        defaultValue={courseRemarkInput}
        onChange={getCourseRemarkInput}
        style={{ width: "690px" }}
      />
    );
  };

  const renderNewCourseItem = (info, index) => {
    switch (index) {
      case 0:
        return renderNewCourseTitleInput(info, index);
      case 1:
        return renderNewCourseContentInput(info, index);
      // case 2:
      //   return renderNewCourseDateInput(info, index);
      case 2:
        return renderNewCourseRemarkInput(info, index);
    }
  };

  const renderHomeworkSubmitContentInput = () => {
    return (
      <>
        {courseName !== "" && <h5>{courseName}</h5>}
        <Select
          native
          value={homeworkIndex}
          onChange={event => {
            let index = event.target.value;
            setHomeworkIndex(index);
          }}
          inputProps={{
            id: "notifytype-native-required"
          }}
        >
          {homeworkList &&
            homeworkList.map((item, index) => {
              return (
                <option value={index} key={index}>
                  {item.name}
                </option>
              );
            })}
        </Select>
      </>
    );
  };

  const renderHomeworkSubmitRemarkInput = () => {
    return (
      <TextField
        label="请输入内容"
        inputProps={{ maxLength: 20 }}
        defaultValue={courseRemarkInput}
        onChange={getCourseRemarkInput}
        style={{ width: "690px" }}
      />
    );
  };

  const renderHomeworkSubmitItem = (info, index) => {
    switch (index) {
      case 0:
        return renderNewCourseTitleInput(info, index);
      case 1:
        return renderHomeworkSubmitContentInput(info, index);
      case 2:
        return renderHomeworkSubmitRemarkInput(info, index);
    }
  };

  const renderNewCourseNotify = () => {
    let item = notifyItem[notifyTypeIndex];
    let infoItem = item.infoItem;
    return infoItem.map((info, index) => {
      return (
        <div key={info.title + index}>
          <h4>{info.title}</h4>
          {renderNewCourseItem(info, index)}
          <div className={classes.divider} />
        </div>
      );
    });
  };

  const renderHomeworkSubmitNotify = () => {
    let item = notifyItem[notifyTypeIndex];
    let infoItem = item.infoItem;
    return infoItem.map((info, index) => {
      return (
        <div key={info.title + index}>
          <h4>{info.title}</h4>
          {renderHomeworkSubmitItem(info, index)}
          <div className={classes.divider} />
        </div>
      );
    });
  };

  const renderNotifyInput = () => {
    switch (notifyTypeIndex) {
      case 0:
      case "0":
        return renderNewCourseNotify();
      case 1:
      case "1":
        return renderHomeworkSubmitNotify();
      case 2:
      case "2":
        return renderHomeworkSubmitNotify();
    }
  };

  return (
    <Paper style={{ padding: "40px" }}>
      <h3>发送小程序订阅消息</h3>
      <h4>选择消息类型</h4>
      <Select
        native
        value={notifyTypeIndex}
        onChange={event => {
          let index = event.target.value;
          setNotifyTypeIndex(index);
          setCourseList([]);
          setCourseContentInput("");
          setCourseDateInput("2019-01-01");
          setCourseNameInput("");
          setCourseRemarkInput("");
          setCourseName("");
          setHomeworkList([]);
          setHomeworkIndex(0);
        }}
        inputProps={{
          id: "notifytype-native-required"
        }}
      >
        {notifyItem.map((item, index) => {
          return (
            <option value={index} key={index}>
              {item.title}
            </option>
          );
        })}
      </Select>

      <div className={classes.divider} />
      {renderNotifyInput()}
      <Button
        variant="contained"
        color="primary"
        style={{ display: "block" }}
        onClick={sendNodify}
        disabled={isSending}
      >
        {isSending ? "发送中..." : "发送"}
      </Button>
      <Snackbar
        autoHideDuration={4000}
        open={tipInfo.isOpen}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{tipInfo.message}</span>}
      />
    </Paper>
  );
};

export default withStyles(styles)(WeChatNotifyPage);
