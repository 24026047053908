/**
 * @description 视频卡片列表
 */
import React from "react";
import {
  List,
  Create,
  Datagrid,
  SelectField,
  TextField,
  NumberField,
  UrlField,
  TextInput,
  SimpleForm
} from "react-admin";
import "./styles.css";

const videoPlatforms = [{ id: "QCLOUD", name: "腾讯云" }];

// 视频连接列表
export const VideoList = props => (
  <List {...props}>
    <Datagrid>
      <NumberField source="id" sortable={false} />
      <SelectField
        source="platform"
        choices={videoPlatforms}
        sortable={false}
      />
      <TextField source="fileId" label="视频 id" sortable={false} />
      <UrlField source="defaultUrl" label="默认链接" sortable={false} />
      <UrlField source="encryptedUrl" label="加密链接" sortable={false} />
      <TextField source="insertedAt" label="创建时间" />
    </Datagrid>
  </List>
);

// 目前视频卡创建未有此功能
export const VideoCreate = props => (
  <Create {...props}>
    <SimpleForm>
      {/* <SelectInput source='platform' choices={videoPlatforms} defaultValue='QCLOUD' /> */}
      <TextInput source="fileId" label="文件 id" />
      {/* <TextInput source='defaultUrl' label='默认链接' /> */}
      {/* <TextInput source='originalUrl' label='初始链接' /> */}
      {/* <TextInput source='encryptedUrl' label='加密链接' /> */}
    </SimpleForm>
  </Create>
);
