/**
 * @description 发送邮件组件
 */
import React from "react";
import axios from "axios";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import config from "../config";
import { sendEmailQuery } from "./../query";
import TextArea from "./../component/TextArea";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarTip from "./../component/snackbarTip";
export const ShortMessage = props => {
  const [textValue, setTextValue] = React.useState([""]);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState([""]);
  function getTextValue(e) {
    setTextValue(e.target.value.split(","));
  }

  function getInputValue(e) {
    setInputValue(e.target.value);
  }

  function handleClose() {
    setOpen(false);
  }

  // 发送邮件
  function sendEmail() {
    axios
      .post(
        config.uri + "api",
        {
          query: sendEmailQuery,
          variables: {
            input: { emails: textValue, templateId: inputValue }
          }
        },
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("token")}`
          }
        }
      )
      .then(res => {
        try {
          if (res.status === 200) {
            if (!res.data.data.sendEmail.errors) {
              setOpen(true);
              setMsg("发送成功");
            } else {
              // 页面打印错误信息
              setOpen(true);
              setMsg(res.data.data.sendEmail.errors[0].message);

              // 授权过期跳转登录界面
              if (
                res.data.data.sendEmail.errors[0].message === "unauthorized"
              ) {
                window.location.href = window.location.origin + "/#/login";
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
  }

  return (
    <Card>
      <CardHeader title="发送邮件" />
      <CardContent>
        <TextArea label="邮箱(用英文逗号隔开)" onChange={getTextValue} />

        <TextField
          label="输入模板ID"
          style={{ width: "690px", marginTop: "20px", marginBottom: "20px" }}
          onChange={getInputValue}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ display: "block" }}
          onClick={sendEmail}
        >
          发送
        </Button>
        <SnackbarTip open={open} msg={msg} handleClose={handleClose} />
      </CardContent>
    </Card>
  );
};

export default ShortMessage;
