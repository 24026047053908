/**
 * @description banner列表
 */
import React from "react";
import {
  UrlField,
  List,
  Create,
  Datagrid,
  TextField,
  Edit,
  EditButton,
  TextInput,
  SimpleForm,
  DisabledInput
} from "react-admin";
import "./styles.css";

export const BannerList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" sortable={false} />
      <UrlField source="imageUrl" sortable={false} />
      <UrlField source="linkUrl" sortable={false} />
      <TextField source="insertedAt" />
      <EditButton />
    </Datagrid>
  </List>
);

export const BannerCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <em>输入的链接值不能与Banners列表的值重复</em>
      <TextInput source="imageUrl" sortable={false} />
      <TextInput source="linkUrl" sortable={false} />
    </SimpleForm>
  </Create>
);

export const BannerEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="imageUrl" sortable={false} />
      <TextInput source="linkUrl" sortable={false} />
    </SimpleForm>
  </Edit>
);
