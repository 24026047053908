/**
 * @description 用户列表功能-增删改查
 */
import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import {
  UrlField,
  List,
  Create,
  Datagrid,
  SelectField,
  TextField,
  NumberField,
  Filter,
  Edit,
  EditButton,
  TextInput,
  SelectInput,
  SimpleForm,
  DisabledInput
} from "react-admin";

import "./styles.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <div>{children}</div>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}
const roleChoices = [
  { id: "USER", name: "普通用户" },
  { id: "CREATIVE", name: "创作者" },
  { id: "COLLABORATOR", name: "协作者" },
  { id: "ADMIN", name: "管理员" }
];

const UserFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="手机号搜索" source="phone" alwaysOn />
      <TextInput label="昵称搜索" source="nickname" />
      <TextInput label="课程类别" source="course" />
    </Filter>
  );
};

export const UserList = props => {
  return (
    <List filters={<UserFilter />} {...props}>
      <Datagrid>
        <TextField source="nickname" label="昵称" sortable={false} />
        <NumberField source="phone" label="手机号" sortable={false} />
        <SelectField source="role" label="角色" choices={roleChoices} />
        <TextField source="id" sortable={false} />
        <TextField source="insertedAt" label="创建时间" />
        {/* <UrlField source="avatar" label="头像" sortable={false} /> */}
        <EditButton />
      </Datagrid>
    </List>
  );
};

const UserTitle = ({ record }) => {
  return <span> {record ? `${record.nickname}` : ""}</span>;
};

export const PasswordEditor = props => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm>
      <TextInput source="phone" />
      <TextInput source="newPassword" />
    </SimpleForm>
  </Edit>
);

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
export const UserEdit = props => {
  const [value, setValue] = React.useState(0);
  function handleChange(e, value) {
    setValue(value);
  }
  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="角色更改" />
          <Tab label="密码更改" />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabContainer>
          <Edit title={<UserTitle />} {...props}>
            <SimpleForm>
              <DisabledInput source="id" />
              <TextInput source="phone" />
              <SelectInput source="role" choices={roleChoices} />
            </SimpleForm>
          </Edit>
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <Edit {...props}>
            <SimpleForm>
              <TextInput source="phone" />
              <TextInput source="password" />
            </SimpleForm>
          </Edit>
        </TabContainer>
      )}
    </div>
  );
};

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="nickname" label="昵称" />
      <TextInput source="phone" label="手机号" />
      <TextInput source="password" label="密码" />
      <TextInput source="avatar" label="头像链接" />
      <SelectInput
        source="role"
        label="角色"
        choices={roleChoices}
        defaultValue="USER"
      />
    </SimpleForm>
  </Create>
);
