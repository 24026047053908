/**
 * @description 数据处理
 */
import * as R from "ramda";
import { list, isNilOrEmpty } from "ramda-adjunct";
import { isUUID, isInt } from "validator";

const b64Encode = R.curry(str => {
  const buffer = Buffer.from(str);
  return buffer.toString("base64");
});

const b64Decode = R.curry(str => {
  const buffer = Buffer.from(str, "base64");
  return buffer.toString();
});

const notEquals = R.compose(R.complement, R.equals);

const isId = R.converge(R.or, [
  R.converge(R.and, [R.endsWith("Id"), notEquals("fileId")]),
  R.equals("id")
]);

const isRUUID = R.curry(isUUID);
const isRInt = R.curry(number => isInt(number, { min: 1, max: 9999999 }));

const decodeId = R.unless(
  R.converge(R.or, [isRUUID, isRInt]),
  R.compose(R.last, R.split(":"), b64Decode)
);

const decodeIdInternal = ([k, v]) => {
  if (isId(k)) {
    return [k, decodeId(v)];
  } else {
    return [k, v];
  }
};

const convertNodeIdValue = m => {
  for (const k in m) {
    if (typeof m[k] === "object") {
      m[k] = R.compose(R.fromPairs, R.map(decodeIdInternal), R.toPairs)(m[k]);
    } else if (isId(k)) {
      m[k] = decodeId(m[k]);
    }
  }
  return m;
};

const parseEdgesForData = resouce =>
  R.compose(
    R.map(R.compose(convertNodeIdValue, R.prop("node"))),
    R.path(["data", resouce, "edges"])
  );

const parseEdgesForTotal = resouce => R.path(["data", resouce, "count"]);

const multPagePerpage = R.converge(R.multiply, [
  R.compose(R.dec, R.prop("page")),
  R.prop("perPage")
]);

const encodeAfter = R.curry(numberOrNull => {
  if (numberOrNull === null) {
    return null;
  } else {
    const str = `arrayconnection:${numberOrNull}`;
    return b64Encode(str);
  }
});

const getAfter = R.compose(
  encodeAfter,
  R.ifElse(R.lt(1), R.dec(), R.always(null)),
  multPagePerpage
);

const defaultTo10 = R.defaultTo(10);

const getPagination = R.applySpec({
  first: R.compose(defaultTo10, R.path(["pagination", "perPage"])),
  after: R.compose(getAfter, R.prop("pagination"))
});

const getFilter = R.pick(["filter"]);

const getOrderValue = R.compose(R.path(["sort", "order"]));

const getOrder = R.ifElse(
  R.compose(isNilOrEmpty, R.prop("sort")),
  R.always({}),
  R.applySpec({ order: getOrderValue })
);

const transformParamsForListing = R.converge(R.compose(R.mergeAll, list), [
  getPagination,
  getFilter,
  getOrder
]);

const transformResourceForListing = R.cond([
  [R.equals("User"), R.always("users")],
  [R.equals("CardVideo"), R.always("cardVideos")],
  [R.equals("CoursePackage"), R.always("coursePackages")],
  [R.equals("Purchase"), R.always("purchases")],
  [R.equals("Course"), R.always("courses")],
  [R.equals("Banner"), R.always("banners")],
  [R.equals("SignCard"), R.always("signCards")],
  [R.equals("Discussion"), R.always("discussions")]
]);

const transformResourceForGetMany = R.cond([
  [R.equals("User"), R.always("getUsers")]
]);

const transformParamsForGet = R.curry((params, resouce) => {
  const str = `${resouce}:${params.id}`;
  return { id: b64Encode(str) };
});

const transformParamsForGetMany = R.curry((params, resouce) => {
  const id = R.compose(R.head, R.prop("ids"))(params);
  const str = `${resouce}:${id}`;
  return { id: b64Encode(str) };
});

// 更新用户密码
const transformParamsForUpdatePassword = R.curry((filter, params) => {
  return { input: R.pick(filter)(params) };
});

// 解析节点
const parseNode = R.compose(convertNodeIdValue, R.path(["data", "node"]));

const fetchData = R.compose(R.toPairs, R.prop("data"));
const fetchPreviousData = R.compose(R.toPairs, R.prop("previousData"));
const diffUpdate = R.converge(R.compose(R.fromPairs, R.difference), [
  fetchData,
  fetchPreviousData
]);
const transformParamsForUpdate = R.applySpec({
  input: R.converge(R.merge, [R.pick(["id"]), diffUpdate])
});

const capitalize = R.compose(
  R.join(""),
  R.juxt([R.compose(R.toUpper, R.head), R.tail])
);

const lower = R.compose(
  R.join(""),
  R.juxt([R.compose(R.toLower, R.head), R.tail])
);
const parseUpdateResult = R.curry(resouceName =>
  R.path(["data", `update${resouceName}`, lower(resouceName)])
);
const parseCreateResult = R.curry(resouceName =>
  R.path(["data", `create${resouceName}`, lower(resouceName)])
);

export {
  parseNode,
  parseEdgesForData,
  parseEdgesForTotal,
  parseUpdateResult,
  parseCreateResult,
  transformParamsForListing,
  transformResourceForGetMany,
  transformResourceForListing,
  transformParamsForGetMany,
  transformParamsForGet,
  transformParamsForUpdate,
  capitalize,
  convertNodeIdValue,
  transformParamsForUpdatePassword
};
