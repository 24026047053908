/**
 * @description 增加协作者权限的组件
 */
import React from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { users, searchPackage, forkPackageTo } from "./../query";
import config from "./../config";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.phone, query);
  const parts = parse(suggestion.phone, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

// 组件样式
const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

class AddCollaborators extends React.Component {
  popperNode = null;

  state = {
    phone: "",
    // 协作者手机号
    collaborators: "",

    packageName: "",
    popper: "",
    suggestions: [],
    // 创建者的卡包
    packageList: [],
    // 协作者的Id
    userId: "",
    // 弹窗是否打开
    open: false,
    // 弹窗信息
    msg: ""
  };
  getSuggestionValue = suggestion => {
    return suggestion;
  };

  getSuggestions = value => {
    return this.state.suggestions ? this.state.suggestions : [];
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  // 【确定按钮】调用函数
  addCollaborators = () => {
    //userId和packageId

    if (this.state.packageList.length !== 0 && this.state.userId) {
      const signlePackage = this.state.packageList
        .filter(item => item.name === this.state.packageName)
        .pop();

      const packageId = window.atob(signlePackage.id).split("Package:")[1];
      const toUserId = window.atob(this.state.userId).split("User:")[1];
      const variables = { input: { packageId, toUserId } };

      axios
        .post(
          config.uri + "api",
          {
            query: forkPackageTo,
            variables
          },
          {
            headers: {
              authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          }
        )
        .then(res => {
          try {
            if (!res.data.data.forkPackageTo.errors) {
              this.setState({
                open: true,
                msg: "fork成功"
              });
            } else {
              this.setState({
                open: true,
                msg: res.data.data.forkPackageTo.errors[0].message
              });
            }
          } catch (e) {
            console.log(e);
          }
        });
    } else {
      if (this.state.packageList.length === 0) {
        this.setState({
          open: true,
          msg: "无卡包"
        });
      } else {
        if (this.state.collaborators.length !== 11) {
          this.setState({
            open: true,
            msg: "协作者的手机号码填写不正确"
          });
        }
      }
    }
  };

  // 选择卡包的函数
  handleSelect = e => {
    this.setState({
      packageName: e.target.value
    });
  };

  // 弹窗组件的关闭函数
  handleClose = () => {
    this.setState({
      open: false
    });
  };

  // 目前此函数暂无用到
  handleBlur = () => {
    if (!this.state.userId && this.state.collaborators.length === 11) {
      //做一个请求获取一个userId

      const input = {
        after: null,
        filter: {
          phone: this.state.collaborators
        },
        first: 10,
        order: "DESC"
      };
      axios
        .post(
          config.uri + "api",
          {
            query: users,
            variables: input
          },
          {
            headers: {
              authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          }
        )
        .then(res => {
          try {
            const list = res.data.data.users.edges.map(item => item.node);

            if (list.length !== 0) {
              this.setState({
                userId: list[0].id
              });
            } else {
              this.setState({
                userId: "",
                msg: "不存此号码",
                open: true
              });
            }
          } catch (err) {
            console.log(err);
          }
        });
    }
  };

  // 协作者和创建者输入框的动态调用函数
  handleChange = name => (event, { newValue }) => {
    let input = null;
    this.setState({
      [name]: typeof newValue === "object" ? newValue.phone : newValue
    });
    //接口请求
    if (name === "collaborators") {
      this.setState({
        [name]: typeof newValue === "object" ? newValue.phone : newValue,
        userId: typeof newValue === "object" ? newValue.id : ""
      });
    }
    input = {
      after: null,
      filter: {
        phone: typeof newValue === "object" ? newValue.phone : newValue
      },
      first: 10,
      order: "DESC"
    };
    axios
      .post(
        config.uri + "api",
        {
          query: users,
          variables: input
        },
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("token")}`
          }
        }
      )
      .then(res => {
        try {
          if (
            res.data.errors &&
            res.data.errors[0].message === "unauthorized"
          ) {
            window.location.href = window.location.origin + "/#/login";
          }
          const list = res.data.data.users.edges.map(item => item.node);
          this.setState({
            suggestions: list
          });
        } catch (err) {
          console.log(err);
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.phone !== this.state.phone &&
      this.state.phone.length === 11
    ) {
      const input = {
        filter: { phone: this.state.phone, name: "" },
        ignoreDiscussion: true
      };
      axios
        .post(
          config.uri + "api",
          {
            query: searchPackage,
            variables: input
          },
          {
            headers: {
              authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          }
        )
        .then(res => {
          try {
            if (res.status === 200) {
              if (!res.data.data.searchPackage.errors) {
                const list = res.data.data.searchPackage;
                this.setState({
                  packageList: list.length === 0 ? [] : list,
                  packageName: list.length === 0 ? "" : list[0].name
                });
              } else {
                if (
                  res.data.data.searchPackage.errors[0].message ===
                  "unauthorized"
                ) {
                  window.location.href = window.location.origin + "/#/login";
                }
              }
            }
          } catch (err) {
            console.log(err);
          }
        });
    }
    if (prevState.collaborators !== this.state.collaborators) {
      if (!this.state.userId && this.state.collaborators.length === 11) {
        //做一个请求获取一个userId

        const input = {
          after: null,
          filter: {
            phone: this.state.collaborators
          },
          first: 10,
          order: "DESC"
        };
        axios
          .post(
            config.uri + "api",
            {
              query: users,
              variables: input
            },
            {
              headers: {
                authorization: `Bearer ${window.localStorage.getItem("token")}`
              }
            }
          )
          .then(res => {
            try {
              const list = res.data.data.users.edges.map(item => item.node);
              if (list.length !== 0) {
                this.setState({
                  userId: list[0].id
                });
              } else {
                this.setState({
                  userId: "",
                  msg: "不存此号码",
                  open: true
                });
              }
            } catch (err) {
              console.log(err);
            }
          });
      }
    }
  }
  render() {
    const { classes } = this.props;
    const { open, msg } = this.state;
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion
    };

    return (
      <Paper
        style={{ padding: "20px" }}
        // className={classes.root}
      >
        <h3>创建者</h3>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: "创建者手机号码",
            value: this.state.phone,
            onChange: this.handleChange("phone")
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
        <div className={classes.divider} />
        <h3>协作者</h3>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: "协作者手机号码",
            value: this.state.collaborators,
            onChange: this.handleChange("collaborators")
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
        <div className={classes.divider} />

        <h3>卡包名称</h3> 
        <Select
          native
          value={this.state.packageName}
          onChange={this.handleSelect}
          name="age"
          inputProps={{
            id: "age-native-required"
          }}
        >
          {this.state.packageList.map((item, index) => (
            <option value={item.name} key={index}>
              {item.name}
            </option>
          ))}
        </Select>

        <div className={classes.divider} />
        <Button variant="outlined" onClick={this.addCollaborators}>
          确定
        </Button>

        {/*弹窗组件 */}
        <Snackbar
          // anchorOrigin={{ vertical, horizontal }}
          autoHideDuration={4000}
          open={open}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{msg}</span>}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(AddCollaborators);
