/**
 * @description 面板组件
 */
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

export default () => {
  const nickname = window.localStorage.getItem("nickname");
  return (
    <Card>
      <CardHeader title="欢迎来到开智学堂管理后台" />
      <CardContent>{nickname + ", 请谨慎操作..."}</CardContent>
    </Card>
  );
};
