// 发送短信
export const sendMessageQuery = `
    mutation sendMessage($input:SendMessageInput!) {
        sendMessage(input:$input) {
            errors {
                code 
                key
                message
            }
        }
    }
`;

// 发送邮件
export const sendEmailQuery = `
    mutation sendEmail($input:SendEmailInput!) {
        sendEmail(input:$input) {
            errors {
                code 
                key
                message
            }
        }
    }
`;

// 发送通知
export const sendNotificationQuery = `
mutation sendNotification($input:SendNotificationInput!) {
    sendNotification(input:$input) {
        errors {
            code 
            key
            message
        }
        notification {
            content
            insertedAt
            unsuccessfulPhones
        }
    }
}
`;

//批量创建用户的课程权限
export const bulkCreatePurchases = `
    mutation bulkCreatePurchases($input: BulkCreatePurchasesInput!) {
        bulkCreatePurchases(input:$input) {
            errors {
                code 
                key 
                message
            }
            unsuccessfulPhones 
        }
    }
`;

export const users = `query($first: Int, $after: String, $filter: UserFilter, $order: SortOrder) {
    users(first: $first, after: $after, filter: $filter, order: $order) {
      count
      edges {
        node {
          id
          nickname
          phone
          avatar
          role
          insertedAt
        }
      }
    }
  }`;

export const searchPackage = `
  query($filter:SearchPackageFilter!,$ignoreDiscussion:Boolean){
        searchPackage(filter:$filter, ignoreDiscussion:$ignoreDiscussion) {
          name
          id
          branch
          location
          description
          owner {
              nickname
              userProfile {
                  name
              }
          }
        }
  }
`;

export const deletePackage = `
  mutation($input:DeletePackageInput!) {
    deletePackage(input: $input) {
        errors {
            code
           
            message
        }
      
    }
  }
`;

export const forkPackageTo = `
  mutation($input:ForkPackageToInput!) {
    forkPackageTo(input:$input){
        errors {
            message
        }
    }
  }
`;

//查找课程
export const searchCourse = `
  query listCourses($filter: CourseFilter, $first: Int) {
    courses(filter: $filter, first: $first) {
       edges {
        node {
          title
          shortUrl
          isEnd
          isOnline
          id
          coursesPackages{
            id
            packageTag
          }
        }
      }
    }
  }
`;

//查找作业

export const searchHomework = `
 query getAllAssignments($id: ID!) {
   node(id: $id) {
     ... on CoursePackage {
       assignments {
         name
         id
       }
     }
   }
 }
`;
