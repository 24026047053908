function download(url, name) {
  const aLink = document.createElement("a");
  aLink.download = name;
  aLink.href = url;
  aLink.dispatchEvent(new MouseEvent("click", {}));
}

export default (data, name, type) => {
  const blob = new Blob([data], { type });

  const url = URL.createObjectURL(blob);

  download(url, name);
};
