/**
 * @description 所有课程的讨论主题的列表
 * @target  后台可以随时关闭某个课程的讨论主题
 */
import React from "react";
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  EditButton,
  Filter,
  TextInput,
  SimpleForm,
  Edit,
  DisabledInput,
  BooleanInput
} from "react-admin";

// 列表数据的过滤搜索
const DiscussionFilter = props => (
  <Filter {...props}>
    <TextInput label="课程名搜索" source="courseTitle" alwaysOn />
  </Filter>
);

// 讨论列表
// name、id、package、status的属性都是后端接口返回的属性，query里面写了什么字段，在组件里便可使用什么字段
export const DiscussionList = props => {
  return (
    <List filters={<DiscussionFilter />} {...props}>
      <Datagrid>
        <TextField source="name" label="讨论主题" />
        <NumberField source="id" />
        <TextField source="package.name" label="卡包名" />
        <BooleanField source="status" label="关闭状态" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

// 讨论的编辑
export const DiscussionEditor = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <DisabledInput source="name" label="主题名" />
        <BooleanInput source="status" label="关闭状态" />
      </SimpleForm>
    </Edit>
  );
};
