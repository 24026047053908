/**
 * @description 登录处理 相关细节见https://react-admin.com/docs/zh-CN/authorization.html
 */
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import config from "./config";
import header from "./accounts/header";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(config.uri + "t/api/admin/login", {
      method: "POST",
      body: JSON.stringify({ phone: username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then(response => {
        if (response.status === 403) {
          throw new Error("无效角色，登录失败");
        }
        if (response.status === 400) {
          throw new Error("无效的密码或手机号");
        }
        return response.json();
      })
      .then(data => {
        const { token, role, nickname } = data;
        if (role === "admin") {
          window.localStorage.setItem("token", token);
          window.localStorage.setItem("nickname", nickname);
          header.Authorization = "Bearer " + token;
        }
      });
  }

  if (type === AUTH_LOGOUT) {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("nickname");
    header.Authorization = "";
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;

    // 权限过期处理
    if (params.message === "GraphQL error: unauthorized") {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("nickname");
      header.Authorization = "";
      return Promise.reject();
    }
    if (status === 401 || status === 403) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("nickname");
      header.Authorization = "";
      return Promise.reject();
    }

    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return window.localStorage.getItem("token")
      ? Promise.resolve()
      : Promise.reject();
  }
};
