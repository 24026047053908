/**
 * @description 删除卡包组件
 */
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { searchPackage, deletePackage } from "./../query.js";
import axios from "axios";
import config from "./../config";
// 弹窗组件
import SnackbarTip from "../component/snackbarTip.js";

export default function DeleteBranch() {
  const [coursePackageValue, setValue] = React.useState("");
  const [branchList, setBranchList] = useState([]);
  const [dialog, setDialog] = useState(false);
  // 删除分支之后的弹窗设置
  const [open, setOpen] = useState(false);
  // 删除弹窗的信息
  const [msg, setMsg] = useState("");
  // 记录需删除的卡包id
  const [id, setId] = useState("");

  // 动态获取卡片的值
  function changeValue(e) {
    setValue(e.target.value);
  }

  // 删除弹窗
  function isOpen(id) {
    setDialog(true);
    setId(id);
  }
  // 删除分支
  function deleteBranch() {
    const packageId = window.atob(id).split("Package:")[1];

    axios
      .post(
        config.uri + "api",
        {
          query: deletePackage,
          variables: { input: { id: packageId } }
        },
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("token")}`
          }
        }
      )
      .then(res => {
        try {
          if (!res.data.data.deletePackage.errors) {
            getBranchPackageList(searchPackage);
            setDialog(false);
            setOpen(true);
            setMsg("删除成功");
          } else {
            if (res.data.data.deletePackage.errors[0].message) {
              setDialog(false);
              setOpen(true);
              setMsg(res.data.data.deletePackage.errors[0].message);
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
  }

  // 获取分支卡包列表
  function getBranchPackageList(searchPackage) {
    axios
      .post(
        config.uri + "api",
        {
          query: searchPackage,
          variables: {
            filter: { name: coursePackageValue, isMaster: false },
            ignoreDiscussion: true
          }
        },
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("token")}`
          }
        }
      )
      .then(res => {
        try {
          // 过期处理，跳转登录界面
          if (
            res.data.errors &&
            res.data.errors[0].message === "unauthorized"
          ) {
            window.location.href = window.location.origin + "/#/login";
          }
          const branchPackageList = res.data.data.searchPackage
            ? res.data.data.searchPackage
            : [];
          setBranchList(branchPackageList);
        } catch (err) {
          console.log(err);
        }
      });
  }
  useEffect(() => {
    if (coursePackageValue.length > 0) {
      getBranchPackageList(searchPackage);
    }
  }, [coursePackageValue]);

  return (
    <Paper style={{ padding: "40px" }}>
      <h3>删除分支卡包</h3>
      <TextField
        label="输入卡包名"
        value={coursePackageValue}
        onChange={changeValue}
        style={{ marginBottom: "50px" }}
      />
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>分支卡包名称</TableCell>
              <TableCell numeric>卡包分支所属人</TableCell>

              <TableCell numeric>分支名</TableCell>
              <TableCell numeric>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {branchList.map(row => {
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell numeric>{row.owner.nickname}</TableCell>
                  <TableCell numeric>{row.branch}</TableCell>
                  <TableCell numeric>
                    <Button onClick={() => isOpen(row.id)} color="secondary">
                      删除
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">确定删除该分支吗？</DialogTitle>

        <DialogActions>
          <Button onClick={() => setDialog(false)} color="primary">
            取消
          </Button>
          <Button color="primary" onClick={deleteBranch} autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarTip open={open} msg={msg} handleClose={() => setOpen(false)} />
    </Paper>
  );
}
