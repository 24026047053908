import { cond, always, equals, T } from 'ramda'

const local = {
  uri: 'http://127.0.0.1:4000/'
}

const dev = {
  uri: 'https://develop.openmindcloud.com/'
}

const prod = {
  uri: 'https://m.openmindcloud.com/'
}

// 读取环境变量
const env = process.env.REACT_APP_ENV

const config = cond([
  [equals('dev'), always(dev)],
  [equals('prod'), always(prod)],
  [T, always(local)]
])(env)

export default config
