import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import chineseMessages from "ra-language-chinese";
import Dashboard from "./Dashboard";
import authProvider from "./authProvider";
import {
  UserList,
  UserEdit,
  UserCreate,
  PasswordEditor
} from "./accounts/users";
import { VideoList, VideoCreate } from "./courses/videos";
import { CoursePackageList, CoursePackageEdit } from "./courses/coursePackages";
import {
  PurchaseList,
  PurchaseEdit,
  PurchaseCreate
} from "./courses/purchases";
import BulkcreatePurchase from "./courses/bulkCreatePurchases";
import { BannerList, BannerCreate, BannerEdit } from "./messaging/banners";

import ShortMessage from "./messaging/shortMessage";
import SendEmail from "./messaging/sendEmail";
import SendNotification from "./messaging/sendNotification";

import { CourseList } from "./courses/courses";
import AddCollaborators from "./component/addCollaborators";
import { DiscussionList, DiscussionEditor } from "./discussionList";
import dataProviderFactory from "./dataProvider";
import DeleteBranch from "./branch/deleteBranch";
import WeChatNotify from './wechat/WeChatNotify';

const messages = {
  cn: chineseMessages
};
// 英文转换中文
const i18nProvider = locale => messages[locale];

class App extends Component {
  constructor() {
    super();
    this.state = { dataProvider: null };
  }

  componentDidMount() {
    //原理请https://react-admin.com/docs/zh-CN/data-providers.html
    dataProviderFactory().then(dataProvider => this.setState({ dataProvider }));
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <div>Loading</div>;
    }
    return (
      <Admin
        locale="cn"
        // 汉化
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
      >
        <Resource
          name="User"
          list={UserList}
          edit={UserEdit}
          create={UserCreate}
          options={{ label: "用户" }}
        />
        <Resource name="Course" list={CourseList} options={{ label: "课程" }} />
        <Resource
          name="Purchase"
          list={PurchaseList}
          edit={PurchaseEdit}
          create={PurchaseCreate}
          options={{ label: "购买" }}
        />
        <Resource
          name="CoursePackage"
          list={CoursePackageList}
          edit={CoursePackageEdit}
          options={{ label: "卡包" }}
        />

        <Resource
          name="Discussion"
          list={DiscussionList}
          edit={DiscussionEditor}
          options={{ label: "讨论主题" }}
        />
        <Resource name="SignCard" />
        <Resource
          name="CardVideo"
          list={VideoList}
          create={VideoCreate}
          options={{ label: "卡片视频" }}
        />
        <Resource
          name="Banner"
          list={BannerList}
          create={BannerCreate}
          edit={BannerEdit}
        />
        <Resource
          name="send_message"
          list={ShortMessage}
          options={{ label: "发送短信" }}
        />
        <Resource
          name="send_email"
          list={SendEmail}
          options={{ label: "发送邮件" }}
        />
        <Resource
          name="send_notification"
          list={SendNotification}
          options={{ label: "发送通知" }}
        />
        <Resource
          name="Add_Collaborators"
          list={AddCollaborators}
          options={{ label: "添加卡包协作者" }}
        />
        <Resource
          name="BulkCreatePurchases"
          list={BulkcreatePurchase}
          options={{
            label: "添加用户课程权限"
          }}
        />
        <Resource name="DeleteBranchPackage" options={{ label: "删除分支卡包" }} list={DeleteBranch} />
        <Resource name="WeChatNotify" options={{ label: "微信小程序订阅消息" }} list={WeChatNotify} />
      </Admin>
    );
  }
}

export default App;
