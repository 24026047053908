/**
 * @description 添加用户购买的课程权限
 */
import React from "react";
import axios from "axios";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import config from "../config";
import { bulkCreatePurchases } from "./../query";
import TextArea from "./../component/TextArea";
import SnackbarTip from "./../component/snackbarTip";
export const BulkCreatePurchases = props => {
  const [textValue, setTextValue] = React.useState([""]);
  const [shortUrls, setShortUrlsValue] = React.useState([""]);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState([""]);
  function handleClose() {
    setOpen(false);
  }
  function getTextValue(e) {
    setTextValue(e.target.value.split(","));
  }

  function getShortUrls(e) {
    setShortUrlsValue(e.target.value.split(","));
  }

  function sendMessage() {
    axios
      .post(
        config.uri + "api",
        {
          query: bulkCreatePurchases,
          variables: {
            input: { phones: textValue, shortUrls: shortUrls }
          }
        },
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("token")}`
          }
        }
      )
      .then(res => {
        try {
          if (
            res.status === 200 &&
            res.data.data.bulkCreatePurchases.errors === null
          ) {
            setOpen(true);
            if (
              res.data.data.bulkCreatePurchases.unsuccessfulPhones.length !== 0
            ) {
              setMsg(res.data.data.bulkCreatePurchases.unsuccessfulPhones);
            } else {
              setMsg("发送成功");
            }
          } else {
            setOpen(true);
            setMsg(res.data.data.bulkCreatePurchases.errors[0].message);
            if (
              res.data.data.bulkCreatePurchases.errors[0].message ===
              "unauthorized"
            ) {
              window.location.href = window.location.origin + "/#/login";
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
  }

  return (
    <Card>
      <CardHeader title="批量创建课程权限" />
      <CardContent>
        <TextArea
          label="手机号码(用英文逗号隔开)"
          onChange={getTextValue}
          rows={10}
        />
        <br />
        <TextArea
          label="课程代码(用英文逗号隔开)"
          onChange={getShortUrls}
          rows={10}
        />
        <br />
        <Button
          variant="contained"
          color="primary"
          style={{ display: "block" }}
          onClick={sendMessage}
        >
          发送
        </Button>
        <SnackbarTip open={open} msg={msg} handleClose={handleClose} />
      </CardContent>
    </Card>
  );
};

export default BulkCreatePurchases;
