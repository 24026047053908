/**
 * @description 弹窗组件
 */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4
  }
});

/**
 * @param open {Boolean}  是否打开弹窗
 * @param msg {Object or String }  弹窗组件的信息
 * @function handleClose 关闭弹窗组件的函数
 */
class SimpleSnackbar extends React.Component {
  render() {
    const { classes, handleClose, open, msg } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={open}
          // autoHideDuration={6000}
          // onClose={handleClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <div id="message-id">
              {typeof msg == "object" ? (
                <div>
                  未发送成功的号码
                  {msg.map(val => (
                    <div key={val}>{val}</div>
                  ))}
                </div>
              ) : (
                msg
              )}
            </div>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SimpleSnackbar);
