/**
 * @description 发送短信的组件
 */
import React from "react";
import axios from "axios";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import config from "../config";
//graphql的查询接口
import { sendMessageQuery } from "./../query";
import TextArea from "./../component/TextArea";
import SnackbarTip from "./../component/snackbarTip";

export const ShortMessage = props => {
  // 获取用户信息
  const [textValue, setTextValue] = React.useState([""]);

  // 获取模板值
  const [inputValue, setInputValue] = React.useState([""]);

  // 控制弹窗隐显问题
  const [open, setOpen] = React.useState(false);

  // 改变弹窗信息
  const [msg, setMsg] = React.useState([""]);

  // 更改手机号
  function getTextValue(e) {
    setTextValue(e.target.value.split(","));
  }

  // 更改模板值
  function getInputValue(e) {
    setInputValue(e.target.value);
  }

  // 关闭弹窗组件
  function handleClose() {
    setOpen(false);
  }

  // 发送短信功能
  function sendMessage() {
    axios
      .post(
        config.uri + "api",
        {
          query: sendMessageQuery,
          variables: {
            input: { phones: textValue, templateId: Number(inputValue) }
          }
        },
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("token")}`
          }
        }
      )
      .then(res => {
        try {
          if (res.status === 200) {
            if (!res.data.data.sendMessage.errors) {
              // 如果请求成功，弹窗且提示信息
              setOpen(true);
              setMsg("发送成功");
            } else {
              setOpen(true);
              setMsg(res.data.data.sendMessage.errors[0].message);
              if (
                res.data.data.sendMessage.errors[0].message === "unauthorized"
              ) {
                window.location.href = window.location.origin + "/#/login";
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
  }

  return (
    <Card>
      <CardHeader title="发送短信" />
      <CardContent>
        <TextArea label="手机号码(用英文逗号隔开)" onChange={getTextValue} />
        <TextField
          label="输入模板ID"
          style={{ width: "690px", marginTop: "20px", marginBottom: "20px" }}
          onChange={getInputValue}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ display: "block" }}
          onClick={sendMessage}
        >
          发送
        </Button>
        {/* 弹窗组件提示 */}
        <SnackbarTip open={open} msg={msg} handleClose={handleClose} />
      </CardContent>
    </Card>
  );
};

export default ShortMessage;
