/**
 * @description 卡包列表和它的编辑和创建
 */
import React from "react";
import {
  Filter,
  CardActions,
  RefreshButton,
  ShowButton,
  List,
  Datagrid,
  SelectField,
  TextField,
  NumberField,
  EditButton,
  Edit,
  TextInput,
  SimpleForm,
  DisabledInput,
  ReferenceInput,
  AutocompleteInput,
  FilterButton,
  ListButton,
  CreateButton,
  DeleteButton,
  Toolbar,
  SaveButton
} from "react-admin";
import Button from "@material-ui/core/Button";
import axios from "axios";
import download from "./download.js";
import config from "./../config";
const packageTags = [
  { id: "CONTENT", name: "内容卡包" },
  { id: "DISCUSSION", name: "讨论卡包" },
  { id: "ASSIGNMENT", name: "作业卡包" }
];

const CoursePackageFilter = props => (
  <Filter {...props}>
    <TextInput label="课程名搜索" source="courseTitle" alwaysOn />
    <TextInput label="卡包名搜索" source="packageName" />
  </Filter>
);

// 下载按钮
function DownloadButton(props) {
  // 每一个组件默认都会接受props也就是每一个列表的记录（record）
  return (
    <Button
      color="primary"
      data-props={props}
      onClick={() => downloadFileName(props.record)}
    >
      {" "}
      下载
    </Button>
  );
}
// 下载文件
function downloadFileName(record) {
  axios({
    url: "t/api/download_package",
    method: "get",
    baseURL: config.uri,
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`
    },
    params: {
      id: record.package.id
    }
  })
    .then(res => {
      // 处理数据

      try {
        if (res.status === 200) {
          download(res.data, record.package.name, "application/zip");
        }
      } catch (err) {
        console.log(err);
      }
    })
    .catch(err => {
      if (err.message.replace(/[^0-9]/gi, "") == 404) {
        alert("git repo or branch not found");
      } else {
        alert(err.message);
      }
    });
}

export const CoursePackageList = props => {
  return (
    <List filters={<CoursePackageFilter />} {...props}>
      <Datagrid>
        <NumberField source="id" sortable={false} />
        <TextField source="course.title" label="课程标题" sortable={false} />
        <TextField source="package.name" label="卡包名称" sortable={false} />
        <SelectField
          source="packageTag"
          label="卡包场景"
          choices={packageTags}
          sortable={false}
        />
        <TextField source="signCard.name" label="标记卡" sortable={false} />
        <TextField source="insertedAt" label="创建时间" />
        <TextField source="owner.nickname" label="创建者" />
        <DownloadButton />

        <EditButton />
      </Datagrid>
    </List>
  );
};

const CoursePackageTitle = ({ record }) => {
  // 获取包id
  window.sessionStorage.setItem("packageId", record.package.id);
  return (
    <span> {record ? `${record.course.title} ${record.packageTag}` : ""}</span>
  );
};

const PostEditActions = ({ basePath, data, resource }) => (
  <CardActions>
    {/* <DeleteButton basePath={basePath} record={data} resource={resource} label="删除标记位" /> */}
    <ListButton basePath={basePath} />
    {/* <RefreshButton /> */}
  </CardActions>
);
const PostCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="保存" submitOnEnter={true} />
    <div style={{ marginRight: "20px" }}></div>
    <DeleteButton label="删除标记位" submitOnEnter={true} />
  </Toolbar>
);
export const CoursePackageEdit = props => {
  // 由于GET_LIST的params请求参数无法传入packageId，暂由浏览器缓存保存卡包id
  window.sessionStorage.setItem("coursagePackageId", props.id);
  return (
    <Edit
      title={<CoursePackageTitle />}
      {...props}
      actions={<PostEditActions />}
    >
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <DisabledInput source="id" label="课程卡包id" />
        <DisabledInput source="course.title" label="课程标题" />
        <DisabledInput source="package.name" label="卡包名称" />
        <DisabledInput source="package.id" label="卡包id" />
        <DisabledInput source="signCard.name" label="当前标记卡" />
        <ReferenceInput
          label="更改标记卡"
          reference="SignCard"
          sort={{ field: "name", order: "DESC" }}
          filterToQuery={searchText => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
