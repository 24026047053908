import gql from "graphql-tag";

const courses = gql`
  query($first: Int, $after: String, $filter: CourseFilter, $order: SortOrder) {
    courses(first: $first, after: $after, filter: $filter, order: $order) {
      count
      edges {
        node {
          title
          id
          owner {
            nickname
          }
          isOnline
          isSelling
          registrationBegin
        }
      }
    }
  }
`;

const getCourse = gql`
  query($id: ID!) {
    node(id: $id) {
      ... on Course {
        title
        id
        owner {
          nickname
        }
        isOnline
        isSelling
        registrationBegin
      }
    }
  }
`;

const users = gql`
  query($first: Int, $after: String, $filter: UserFilter, $order: SortOrder) {
    users(first: $first, after: $after, filter: $filter, order: $order) {
      count
      edges {
        node {
          id
          nickname
          phone
          avatar
          role
          insertedAt
        }
      }
    }
  }
`;

const updateUser = gql`
  mutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        nickname
        role
        avatar
        phone
      }
      errors {
        key
        message
      }
    }
  }
`;

const getUser = gql`
  query($id: ID!) {
    node(id: $id) {
      ... on User {
        id
        nickname
        phone
        avatar
        role
        insertedAt
      }
    }
  }
`;

const createUser = gql`
  mutation($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        nickname
        role
        phone
        avatar
        insertedAt
      }
      errors {
        key
        message
      }
    }
  }
`;

const deleteUser = gql`
  mutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

const cardVideos = gql`
  query($first: Int, $after: String, $filter: UserFilter, $order: SortOrder) {
    cardVideos(first: $first, after: $after, filter: $filter, order: $order) {
      count
      edges {
        node {
          id
          platform
          fileId
          defaultUrl
          encryptedUrl
          insertedAt
        }
      }
    }
  }
`;

const createCardVideo = gql`
  mutation($input: CreateCardVideoInput!) {
    createCardVideo(input: $input) {
      cardVideo {
        id
        platform
        fileId
        defaultUrl
        originalUrl
        encryptedUrl
        insertedAt
      }
      errors {
        key
        message
      }
    }
  }
`;

const coursePackages = gql`
  query(
    $first: Int!
    $after: String
    $filter: CoursePackageFilter
    $order: SortOrder
  ) {
    coursePackages(
      first: $first
      after: $after
      order: $order
      filter: $filter
    ) {
      edges {
        node {
         
          course {
            title
          }
          owner {
            id
            nickname
          }
          package {
            name
            id
          }
          id
          packageTag
          signCard {
            id
            name
          }
          insertedAt
        }
      }
      count
    }
  }
`;

const getCoursePackage = gql`
  query($id: ID!) {
    node(id: $id) {
      ... on CoursePackage {
        id
        package {
          id
          name
        }
        course {
          title
        }
        packageTag
        signCard {
          id
          name
          location
        }
      }
    }
  }
`;

const updateCoursePackage = gql`
  mutation($input: UpdateCoursePackageInput!) {
    updateCoursePackage(input:$input) {
      CoursePackage {
        course {
          title
        }
        owner {
          id
          nickname
        }
        package {
          name
          id
        }
        id
        packageTag
        signCard {
          id
          name
        }
        insertedAt
      
      }
      errors {
        code 
        key
        message
      }
  }
}
`
const getCard = gql`
   query($id:ID!) {
     node (id:$id){
      ... on Card {
        id
        name
        location
      }
     }
   }
`
const deleteSignCard = gql`
mutation($input: UpdateCoursePackageInput!) {
  updateCoursePackage(input:$input) {
    CoursePackage {
      package {
        id
        name
      }
      course {
        title
      }
      packageTag
      id
      signCard {
        id
        name
      }
    }
  }
}
`;

const purchases = gql`
  query(
    $first: Int!
    $after: String
    $filter: PurchaseFilter
    $order: SortOrder
  ) {
    purchases(first: $first, after: $after, order: $order, filter: $filter) {
      count
      edges {
        node {
          course {
            title
          }
          user {
            nickname
            phone
          }
          price
          from
          id
          status
          insertedAt
        }
      }
    }
  }
`;

const getPurchase = gql`
  query($id: ID!) {
    node(id: $id) {
      ... on Purchase {
        course {
          title
        }
        user {
          nickname
          phone
        }
        price
        from
        id
        status
        insertedAt
      }
    }
  }
`;

const updatePurchase = gql`
  mutation($input: UpdatePurchaseInput!) {
    updatePurchase(input: $input) {
      purchase {
        course {
          title
        }
        user {
          nickname
          phone
        }
        id
        status
        price
      }
    }
  }
`;

const createPurchase = gql`
  mutation($input: CreatePurchaseInput!) {
    createPurchase(input: $input) {
      purchase {
        course {
          title
        }
        user {
          nickname
          phone
        }
        from
        id
      }
      errors {
        key
        message
      }
    }
  }
`;

const banners = gql`
  query($order: SortOrder) {
    banners(order: $order) {
      id
      linkUrl
      imageUrl
      insertedAt
    }
  }
`;

const createBanner = gql`
  mutation($input: CreateBannerInput!) {
    createBanner(input: $input) {
      banner {
        id
        linkUrl
        imageUrl
        insertedAt
      }
      errors {
        key
        message
      }
    }
  }
`;
const updateBanner = gql`
  mutation($input: UpdateBannerInput!) {
    updateBanner(input: $input) {
      banner {
        id
        linkUrl
        imageUrl
        insertedAt
      }
      errors {
        key
        message
      }
    }
  }
`;

const deleteBanner = gql`
  mutation($input: DeleteBannerInput!) {
    deleteBanner(input: $input) {
      banner {
        id
        linkUrl
        imageUrl
        insertedAt
      }
      errors {
        key
        message
      }
    }
  }
`;

const getBanner = gql`
  query($id: ID!) {
    node(id: $id) {
      ... on Banner {
        id
        linkUrl
        imageUrl
        insertedAt
      }
    }
  }
`;
const signCards = gql`
  query($packageId: ID!, $filter: SignCardFilter,$order:SortOrder,$first:Int,$after:String) {
    signCards(packageId: $packageId, filter:$filter,order:$order,first:$first,after:$after) {
      edges {
        node {
          id
          name
          location
          package {
            id
            name
          }
        }
      }
      count
    }
  }
`;


const updatePassword = gql`
  mutation($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      errors {
        code
        key
        message
      }
      User {
        nickname
        role
        phone
        avatar
        id
      }
    }
  }
`;

const discussions = gql`
   query($first:String,$filter: AdminDiscussionFilter,$after:String,$order:SortOrder) {
    discussions(first:$first,after:$after,filter:$filter,order:$order) {
      count 
      edges {
        node {
          id
          name
          status
          package {
            id
            name
          }
        }
      }
    }
   }
`

const getDiscussion = gql`
   query($id:ID!) {
     node(id:$id){
       ... on Discussion {
          id
          name
          status
          package {
            id
            name
          }
       }
     }
   }
`

const updateDiscussion = gql`
   mutation($input:UpdateDiscussionStatusInput!) {
    updateDiscussionStatus(input:$input) {
      discussion {
          id
          name
          status
          package {
            id
            name
          }
      }
      errors {
        code
        message
      }
    }
   }
`
export {
  courses,
  getCourse,
  users,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  cardVideos,
  createCardVideo,
  coursePackages,
  getCoursePackage,
  updateCoursePackage,
  deleteSignCard,
  purchases,
  getPurchase,
  updatePurchase,
  createPurchase,
  banners,
  getBanner,
  createBanner,
  updateBanner,
  deleteBanner,
  signCards,
  updatePassword,
  getCard,
  discussions,
  getDiscussion,
  updateDiscussion
};
