/**
 * @description 发送通知组件
 */
import React from "react";
import axios from "axios";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import config from "../config";
import TextArea from "./../component/TextArea";
import { sendNotificationQuery } from "./../query";
import SnackbarTip from "./../component/snackbarTip";
export const ShortMessage = props => {
  const [textValue, setTextValue] = React.useState([""]);
  const [inputValue, setInputValue] = React.useState([""]);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState([""]);

  function getTextValue(e) {
    setTextValue(e.target.value.split(","));
  }
  function handleClose() {
    setOpen(false);
  }
  function getInputValue(e) {
    setInputValue(e.target.value);
  }

  function sendMessage() {
    axios
      .post(
        config.uri + "api",
        {
          query: sendNotificationQuery,
          variables: {
            input: { phones: textValue, content: inputValue }
          }
        },
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("token")}`
          }
        }
      )
      .then(res => {
        if (res.status === 200) {
          if (!res.data.data.sendNotification.errors) {
            setOpen(true);
            if (
              res.data.data.sendNotification.notification.unsuccessfulPhones
                .length !== 0
            ) {
              setMsg(
                res.data.data.sendNotification.notification.unsuccessfulPhones
              );
            } else {
              setMsg("发送成功");
            }
          } else {
            setOpen(true);
            setMsg(res.data.data.sendNotification.errors[0].message);
            if (
              res.data.data.sendNotification.errors[0].message ===
              "unauthorized"
            ) {
             window.location.href = window.location.origin + "/#/login";
            }
          }
        }
      });
  }

  return (
    <Card>
      <CardHeader title="发送通知" />
      <CardContent>
        <TextArea label="手机号码(用英文逗号隔开)" onChange={getTextValue} />
        <TextField
          label="输入内容"
          onChange={getInputValue}
          style={{ width: "690px", marginTop: "20px", marginBottom: "20px" }}
        />

        <Button
          variant="contained"
          color="primary"
          style={{ display: "block" }}
          onClick={sendMessage}
        >
          发送
        </Button>

        <SnackbarTip open={open} msg={msg} handleClose={handleClose} />
      </CardContent>
    </Card>
  );
};

export default ShortMessage;
